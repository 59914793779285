/**
 * @ComponentFor ImageContentBlock
 */

import React from 'react';
import { Breakpoint } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import ImageContentBlockType from './ImageContentBlock.type';
import ImageBlockType from 'Shared/Blocks/Image/ImageBlock.type';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import { minSmallMediaQuery, minMediumMediaQuery, pixelsToUnit, white } from 'Shared/Style';

type ImageBlockPropType = {
  block: ImageBlockType;
  componentName: string;
  width: string;
};

type ConnectStateType = {
  cloakedImages: boolean;
  currentBreakpoint: number;
};
type PropType = ImageContentBlockType & ConnectStateType;

const ProductImageBlock = (props: PropType) => {
  const items = epiPropertyValue(props.items);
  const isMobile = props.currentBreakpoint < Breakpoint.Small;

  return (
    <ImageContainer>
      {items.items.map((item: ImageBlockPropType, index) => (
        <Item key={index + Date.now()}>
          <Image
            alt={epiPropertyValue(item.block?.altText)}
            itemProp="image"
            preset={
              !epiPropertyValue(item.block?.imageUrl)?.url.includes('.gif')
                ? !isMobile
                  ? ImagePreset.Large
                  : ImagePreset.Small
                : undefined
            }
            ratio={ImageRatio.ThreeToTwo}
            src={epiPropertyValue(item.block?.imageUrl)?.url}
          />
        </Item>
      ))}
    </ImageContainer>
  );
};

export default connect(
  (state): ConnectStateType => ({
    cloakedImages: state.appShellData.cloakProductImages,
    currentBreakpoint: state.currentBreakpoint,
  }),
)(ProductImageBlock);

const ImageContainer = styled.div({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  backgroundColor: white,
  marginTop: pixelsToUnit(15),
  padding: {
    x: pixelsToUnit(7),
  },
  [minMediumMediaQuery]: {
    justifyContent: 'space-around',
  },
  [minSmallMediaQuery]: {
    flexFlow: 'row wrap',
    padding: {
      x: pixelsToUnit(15),
    },
  },
});

const Item = styled.div({
  width: '100%',
  padding: {
    xy: pixelsToUnit(8),
  },
  [minSmallMediaQuery]: {
    width: '50%',
  },
});
